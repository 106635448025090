import React from "react";
import { Helmet } from "react-helmet";
import { Col, Row } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";

import { DataTable } from "../../components/DataTable";
import viewIcon from "../../assets/images/app/common/listing-view-image.svg";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { SummaryCard } from "../../components/SummaryCard";
import { LabeledDataItem } from "../../components/LabeledDataItem";
import { getKey, MODULE_KEYS } from "../../api/api.keys";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { GetJobs, GetJobStatsById } from "../../api/api.service";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { formatKey, hasData, truncateString } from "../../utils/commonUtils";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { monthYear } from "../../utils/dateUtils";
import { Pagination } from "../../components/Pagination";
import { JobsStatus } from "../../constants";

const HEADINGS = {
    JOBS_RUNNING: "Jobs Running: ",
    JOBS_APPLIED: "Jobs Applied: ",
    JOBS_IN_PROGRESS: "Jobs in Progress: ",
    JOBS_COMPLETED: "Jobs Completed: ",
    JOBS_INVITES: "Jobs Invites: ",
    JOBS_CANCELLED: "Jobs Cancelled: "
};

const COLUMNS = {
    JOB_TITLE: "Job Title",
    CATEGORY: "Category",
    SEEKER_NAME: "Seeker Name",
    POSTED_DATE: "Posted Date",
    HIRING_DATE: "Hiring Date",
    CURRENT_STATUS: "Current Status",
    LOCATION: "Location",
    JOB_ID: "Job ID",
    JOB_TYPE: "Job Type",
    JOB_CITY: "Job City",
    ACTIONS: "Actions"
};

const PROVIDER = "PROVIDER";

const DetailJob = () => {
    const history = useHistory();
    const { providerId } = useParams();
    const {
        isFetching: isJobListingFetched,
        page,
        total,
        data: jobListing,
        limit,
        searchText,
        handlePageClick,
        handleSearchChange,
        handleSortingChange
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.JOB_MANAGEMENT_LISTING]),
        requestFn: GetJobs,
        dataKey: "data",
        totalKey: "total",
        params: {
            type: PROVIDER,
            assigneeId: providerId
        }
    });

    const { useFetch } = useRequestProcessor();

    const { data, isLoading, isFetching } = useFetch(
        getKey([MODULE_KEYS.PROVIDER_JOB_DETAIL], { providerId }),
        async ({ signal }) =>
            await GetJobStatsById({
                Id: providerId,
                params: {
                    type: PROVIDER
                },
                signal
            })
    );

    if (isFetching || isLoading || !hasData(data)) {
        return (
            <div style={{ height: "70vh" }}>
                <LoaderFallbackUI isLoader={isFetching} className={"h-100"} />
            </div>
        );
    }

    return (
        <div className="mt-4">
            <Helmet>
                <title>Provider Management</title>
            </Helmet>
            <SummaryCard title="Jobs Overview">
                <Row>
                    <Col md={12}>
                        <div style={{ color: "black" }} className="my-2">
                            <Row>
                                <Col md={4}>
                                    <LabeledDataItem
                                        title={HEADINGS.JOBS_APPLIED}
                                        desc={data?.totalAppliedJobs || "N/A"}
                                        titleColProps={{ xs: 8, md: 8 }}
                                        descColProps={{ xs: 4, md: 4 }}
                                    />
                                </Col>
                                <Col md={4}>
                                    <LabeledDataItem
                                        title={HEADINGS.JOBS_IN_PROGRESS}
                                        desc={data?.totalInProgressJobs || "N/A"}
                                        titleColProps={{ xs: 8, md: 8 }}
                                        descColProps={{ xs: 4, md: 4 }}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div style={{ color: "black" }} className=" my-2">
                            <Row>
                                <Col md={4}>
                                    <LabeledDataItem
                                        title={HEADINGS.JOBS_COMPLETED}
                                        desc={data?.totalCompletedJobs || "N/A"}
                                        titleColProps={{ xs: 8, md: 8 }}
                                        descColProps={{ xs: 4, md: 4 }}
                                    />
                                </Col>

                                <Col md={4}>
                                    <LabeledDataItem
                                        title={HEADINGS.JOBS_CANCELLED}
                                        desc={data?.totalCancelledJobs || "N/A"}
                                        titleColProps={{ xs: 8, md: 8 }}
                                        descColProps={{ xs: 4, md: 4 }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <hr className={"my-3 hr-classes"} />
                <Row>
                    <Col>
                        <DataTable
                            tableContainerClass="mt-3"
                            loading={isJobListingFetched}
                            data={jobListing}
                            config={[
                                {
                                    title: COLUMNS.JOB_ID,
                                    render: (data) => <div>{data?.id || "-"}</div>
                                },
                                {
                                    title: COLUMNS.JOB_TITLE,
                                    render: (data) => (
                                        <div>{truncateString(data?.title, 30) || "-"}</div>
                                    )
                                },
                                {
                                    title: COLUMNS.CATEGORY,
                                    render: (data) => (
                                        <div> {formatKey(data?.category?.name) || "-"} </div>
                                    )
                                },
                                {
                                    title: COLUMNS.SEEKER_NAME,
                                    render: (data) => (
                                        // <div>{truncateString(data?.assignee?.name, 30) || "-"}</div>
                                        <div>{truncateString(data?.user?.name, 30) || "-"}</div>
                                    )
                                },
                                {
                                    title: COLUMNS.POSTED_DATE,
                                    render: (data) => monthYear("DD/MM/YY ", data?.createdAt) || "-"
                                },
                                {
                                    title: COLUMNS.HIRING_DATE,
                                    render: (data) => (
                                        <div>{monthYear("DD/MM/YY ", data?.assignedAt) || "-"}</div>
                                    )
                                },
                                {
                                    title: COLUMNS.CURRENT_STATUS,
                                    render: (data) => (
                                        <div
                                            className={`job-status-common ${JobsStatus.getLabelClass(
                                                data?.status
                                            )}`}
                                        >
                                            {JobsStatus.getDisplayTextKey(data?.status) || "-"}
                                        </div>
                                    )
                                },

                                {
                                    title: COLUMNS.JOB_CITY,
                                    render: (data) => <div>{data?.city || "-"}</div>
                                },

                                {
                                    title: COLUMNS.JOB_TYPE,
                                    render: (data) => <div>{data?.jobType || "-"}</div>
                                },

                                {
                                    title: COLUMNS.ACTIONS,
                                    render: (data) => (
                                        <img
                                            style={{
                                                filter: "brightness(0) saturate(100%) invert(58%) sepia(21%) saturate(708%) hue-rotate(283deg) brightness(83%) contrast(82%)"
                                            }}
                                            className="me-3"
                                            src={viewIcon}
                                            alt={"details icon"}
                                            role="button"
                                            onClick={() =>
                                                history.push(
                                                    getParamsAttachedRoute(
                                                        APP_ROUTES.JOB_LISTING_DETAIL,
                                                        {
                                                            jobId: data?.id
                                                        }
                                                    )
                                                )
                                            }
                                        />
                                    )
                                }
                            ]}
                        />
                    </Col>
                </Row>
            </SummaryCard>
            <Pagination
                rowLimit={limit}
                onPageClick={handlePageClick}
                currentPage={page}
                totalData={total}
                handleChange={(e) => handleSortingChange(e.target.value)}
            />
        </div>
    );
};

export default DetailJob;
