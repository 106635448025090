import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import { DataTable } from "../../components/DataTable";
import { SummaryCard } from "../../components/SummaryCard";
import { LabeledDataItem } from "../../components/LabeledDataItem";
import { getKey, MODULE_KEYS } from "../../api/api.keys";
import { GetCareProfiles } from "../../api/api.service";
import {
    formatKey,
    hasData,
    replaceUnderscoreWithSpace,
    truncateString
} from "../../utils/commonUtils";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { Pagination } from "../../components/Pagination";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { monthYear } from "../../utils/dateUtils";
import { SearchBox } from "../../components/SearchBox";
import { CareType } from "../../constants";
import { CARE_TYPE } from "../../constants/CareType";

const formatString = (input) => {
    // Convert the input to lowercase and split into words
    let words = input.toLowerCase().split(/[_\s]+/);

    // Capitalize the first letter of each word
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
};

const COLUMNS = {
    S_NO: "S.No",
    NAME: "Name",
    PET_NAME: "Pet Name",
    PET_TYPE: "Pet Type",
    AGE: "Age",
    AGE_GROUP: "Age Group",
    GENDER: "Gender",
    DOB: "Date of birth",
    TYPE: "Type"
};
const format = "Do MMMM YYYY";
const commonWidthClass = "col-width";
const CHARACTER_LIMIT = 20;

// ChildCare columns
const childCareColumns = [
    {
        title: COLUMNS.NAME,
        render: (data) => (
            <div data-container={data?.name}>
                {truncateString(data?.name, CHARACTER_LIMIT) || "-"}
            </div>
        ),
        className: commonWidthClass
    },
    {
        title: COLUMNS.AGE_GROUP,
        render: (data) => data?.childCareProfile?.ageGroup || "-",
        className: commonWidthClass
    },
    {
        title: COLUMNS.GENDER,
        render: (data) => formatKey(data?.childCareProfile?.gender) || "-",
        className: commonWidthClass
    }
];

// SeniorCare columns
const seniorCareColumns = [
    {
        title: COLUMNS.NAME,
        render: (data) => (
            <div data-container={data?.name}>
                {truncateString(data?.name, CHARACTER_LIMIT) || "-"}
            </div>
        ),
        className: commonWidthClass
    },
    {
        title: COLUMNS.DOB,
        render: (data) => monthYear(format, data?.seniorCareProfile?.dob) || "-",
        className: commonWidthClass
    },
    {
        title: COLUMNS.GENDER,
        render: (data) => formatString(data?.seniorCareProfile?.gender) || "-",
        className: commonWidthClass
    }
];

// PetCare columns
const petCareColumns = [
    {
        title: COLUMNS.PET_NAME,
        render: (data) => (
            <div data-container={data?.name}>
                {truncateString(data?.name, CHARACTER_LIMIT) || "-"}
            </div>
        ),
        className: commonWidthClass
    },
    {
        title: COLUMNS.PET_TYPE,
        render: (data) => data?.petCareProfile?.type || "-",
        className: commonWidthClass
    },
    {
        title: COLUMNS.AGE,
        render: (data) => data?.petCareProfile?.age || "-",
        className: commonWidthClass
    }
];

const Detail_CareProfile = () => {
    const { seekerId } = useParams();

    const {
        isFetching,
        page,
        total,
        isLoading,
        data,
        limit,
        searchText,
        handlePageClick,
        handleSearchChange,
        handleSortingChange
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.SEEKER_CARE_PROFILE]),
        dataKey: "data",
        totalKey: "total",

        requestFn: GetCareProfiles,
        params: { userId: seekerId }
    });

    // Filter the data for ChildCare, SeniorCare, and PetCare
    let childCareData = data ? data.filter((care) => care.type === CARE_TYPE.CHILD_CARE) : [];
    let seniorCareData = data ? data.filter((care) => care.type === CARE_TYPE.SENIOR_CARE) : [];
    let petCareData = data ? data.filter((care) => care.type === CARE_TYPE.PET_CARE) : [];

    return (
        <div className="mt-4">
            <Helmet>
                <title>Seeker Management</title>
            </Helmet>
            <Container fluid>
                <Row className="mb-3">
                    <Col sm="12" md="3" lg="3"></Col>
                    <Col sm="12" md="6" lg="6"></Col>
                    <Col sm="12" md="3" lg="3">
                        <SearchBox
                            className="me-3 w-50"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Col>
                </Row>

                <SummaryCard title="Child Care Profiles Overview">
                    <Row>
                        <DataTable
                            loading={isFetching}
                            tableContainerClass="my-3"
                            data={childCareData}
                            config={childCareColumns}
                            equalColumnWidth={"table-fixed"}
                        />
                    </Row>
                </SummaryCard>

                <SummaryCard title="Senior Care Profiles Overview">
                    <Row>
                        <DataTable
                            loading={isFetching}
                            tableContainerClass="my-3"
                            data={seniorCareData}
                            config={seniorCareColumns}
                            equalColumnWidth={"table-fixed"}
                        />
                    </Row>
                </SummaryCard>

                <SummaryCard title="Pet Care Profiles Overview">
                    <Row>
                        <DataTable
                            loading={isFetching}
                            tableContainerClass="my-3"
                            data={petCareData}
                            config={petCareColumns}
                            equalColumnWidth={"table-fixed"}
                        />
                    </Row>
                </SummaryCard>

                <Pagination
                    rowLimit={limit}
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                />
            </Container>
        </div>
    );
};

export default Detail_CareProfile;
