import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "reactstrap";

import { Pagination } from "../../components/Pagination";
import { DataTable } from "../../components/DataTable";
import { GetCSV, GetUsers } from "../../api/api.service";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { PageTitles } from "../../constants";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { CustomButton } from "../../components/CustomButton";
import { SearchBox } from "../../components/SearchBox";
import DateRangePicker from "./DateRangePicker";
import { monthYear } from "../../utils/dateUtils";
import { downloadXlsData } from "../../utils/downloadUtils";
import { ERROR, showToast } from "../../utils/toastUtils";
import { convertTime } from "../../utils/dateFormatUtils";

const COLUMN_HEADER = {
    S_NO: "S.No",
    EMAIL: "Email",
    SIGN_UP_DATE: "Sign-Up Date",
    USER_TYPE: "User Type",
    LAST_LOGIN: "Last Login"
};

const UserManagement = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isCSVDownload, setIsCSVDownload] = useState(false);

    const isDownloadEnabled = !!(startDate && endDate);
    const formattedStartDate = monthYear("YYYY-MM-DD", startDate);
    const formattedEndDate = monthYear("YYYY-MM-DD", endDate);

    const {
        isFetching,
        page,
        total,
        data,
        searchText,
        limit,
        handlePageClick,
        handleSearchChange,
        handleSortingChange,
        setPageNumberOne
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.USER_MANAGEMENT_LISTING]),
        requestFn: GetUsers,
        dataKey: "data",
        totalKey: "total",
        params: {
            ...(startDate && { startDate: formattedStartDate }),
            ...(endDate && { endDate: formattedEndDate })
        }
    });

    const downloadCSVHandler = async () => {
        if (!isDownloadEnabled) return;

        setIsCSVDownload(true);
        try {
            let response = await GetCSV(formattedStartDate, formattedEndDate);
            // Ensure we check for a successful response
            if (!response.ok) {
                showToast(ERROR, "Network response was not ok");
            }

            // Read the response as text (CSV)
            const text = await response.text();
            const blob = new Blob([text], { type: "text/csv" }); // Create a Blob for CSV

            // Call the download function
            await downloadXlsData(blob);
        } catch (error) {
            console.error("Error downloading CSV:", error);
        } finally {
            setIsCSVDownload(false);
        }
    };

    const handleChangeStart = (date) => {
        setPageNumberOne();
        setStartDate(date);
    };

    const handleChangeEnd = (date) => {
        setPageNumberOne();
        setEndDate(date);
    };

    return (
        <div className="page-content">
            <Helmet>
                <title>{PageTitles.USER_MANAGEMENT}</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col sm="12" md="3" lg="3">
                        <SearchBox
                            className="me-3 w-50 my-1"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Col>
                    <Col sm="12" md="2" lg="2"></Col>
                    <Col sm="12" md="3" lg="3">
                        <div className="d-flex justify-content-end">
                            <CustomButton
                                loading={isCSVDownload}
                                title="Download"
                                color="primary"
                                className="custom-btn-md w-100 max-width-percent-modified btn-height my-1"
                                onClick={downloadCSVHandler}
                                disabled={!isDownloadEnabled}
                            />
                        </div>
                    </Col>
                    <Col sm="12" md="4" lg="4" align="right">
                        <DateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            handleChangeStart={handleChangeStart}
                            handleChangeEnd={handleChangeEnd}
                        />
                    </Col>
                </Row>

                <DataTable
                    tableContainerClass="mt-3"
                    loading={isFetching}
                    data={data}
                    isHeightRequired={true}
                    config={[
                        {
                            title: COLUMN_HEADER.S_NO,
                            render: (data) => <div className="">{data?.id || "-"}</div>
                        },
                        {
                            title: COLUMN_HEADER.EMAIL,
                            render: (data) => <div className="">{data?.email || "-"}</div>
                        },

                        {
                            title: COLUMN_HEADER.SIGN_UP_DATE,
                            render: (data) => (
                                <div className="">
                                    {convertTime({
                                        tz: "LOCAL_TIME",
                                        time: data?.createdAt,
                                        format: "DD/MM/YY"
                                    })}
                                </div>
                            )
                        }
                    ]}
                />

                <Pagination
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                    rowLimit={limit}
                />
            </Container>
        </div>
    );
};

export default UserManagement;
